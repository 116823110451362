import { Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import {
  accountNotSupported,
  accountNumberIncorrect,
  accountSwitched,
  invalidSecondaryIdentifier,
  noMatch,
  optedOut,
  sortCodeNotSupported,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  CheckPayeeMatchStatus,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import {
  SetCopResponseStateMatchStatusCode,
  SetCopResponseStateName,
  SetCopResponseStateReason,
} from '../../../actions/cop-response.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { CheckPayeeResponse, Payee } from '../../../services/account-service/account.type';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { AccountState, PaymentGatewayConfig } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function updateBeneficiaryDetail(store: Store, router: Router, accountService: AccountService) {
  const formActionStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
  );
  const formValues = formActionStateData?.formData;
  const copCheckpayload = {
    accountNumber: formValues.payload.destinationIdentifier?.accountNumber,
    accountType: formValues.payload?.accountType,
    name: formValues.payload?.name,
    sortCode: formValues.payload.destinationIdentifier?.sortCode,
    srd: formValues.payload?.srd ?? '',
  };
  const selectedAccount = store.selectSnapshot(AccountState.getCurrentAccount);
  if (selectedAccount?.currency === 'GBP') {
    copCheck(OtpParam.EditBeneficiary, router, store, accountService, copCheckpayload);
  } else {
    router.navigateByUrl(
      `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/review-information-match?flow=${
        OtpParam.EditBeneficiary
      }`,
    );
  }
}

export function copCheck(
  param: string,
  router: Router,
  store: Store,
  accountService: AccountService,
  copCheckpayload: Payee,
) {
  let payeeEnabled = false;
  store
    .selectOnce(AccountState.getPaymentGatewayConfig)
    .pipe(
      map((paymentGatewayConfig: PaymentGatewayConfig) => {
        payeeEnabled = paymentGatewayConfig?.checkPayeeEnabled;
      }),
    )
    .subscribe();
  if (payeeEnabled) {
    accountService
      .checkPayee({ ...copCheckpayload } as Payee, param)
      .subscribe((checkPayeeResponse: CheckPayeeResponse) => {
        store.dispatch(new SetCopResponseStateName(checkPayeeResponse.name));
        store.dispatch(new SetCopResponseStateMatchStatusCode(checkPayeeResponse.matchStatusCode));
        if (checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.MATCHED) {
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/review-information-match-cop?flow=${param}`,
          );
        } else if (
          checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.CLOSE_MATCH ||
          checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.NAME_MATCHED ||
          checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.ACCOUNT_TYPE_MISMATCH_NAME_CLOSE_MATCH
        ) {
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/review-information-close-match-cop?flow=${param}`,
          );
        } else {
          if (checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.INVALID_SECONDARY_IDENTIFIER) {
            router.navigateByUrl(
              `/zones/${formatNameAsUrl(
                APP_ZONES.PAYMENT,
              )}/review-information-secondary-identifier-required-cop?flow=${param}`,
            );
          } else {
            setNoMatchReasonFromResponse(checkPayeeResponse.matchStatusCode, store);
            router.navigateByUrl(
              `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/review-information-no-match-cop?flow=${param}`,
            );
          }
        }
      });
  } else {
    // don't check the payee
    router.navigateByUrl(
      `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/review-information-match?flow=${param}`,
    );
  }
}

function setNoMatchReasonFromResponse(response: string, store: Store) {
  let noMatchReason: string;
  switch (response) {
    case CheckPayeeMatchStatus.ACCOUNT_SWITCHED:
      noMatchReason = accountSwitched;
      break;
    case CheckPayeeMatchStatus.ACCOUNT_NOT_SUPPORTED:
      noMatchReason = accountNotSupported;
      break;
    case CheckPayeeMatchStatus.OPTED_OUT:
      noMatchReason = optedOut;
      break;
    case CheckPayeeMatchStatus.INVALID_SECONDARY_IDENTIFIER:
      noMatchReason = invalidSecondaryIdentifier;
      break;
    case CheckPayeeMatchStatus.SORT_CODE_NOT_SUPPORTED:
      noMatchReason = sortCodeNotSupported;
      break;
    case CheckPayeeMatchStatus.ACCOUNT_NUMBER_INCORRECT:
      noMatchReason = accountNumberIncorrect;
      break;
    default:
      noMatchReason = noMatch;
      break;
  }
  store.dispatch(new SetCopResponseStateReason(noMatchReason));
}

export function editbeneficiaryButtonState(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
) {
  const data = store.select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY));

  return data.subscribe({
    next: (res) => {
      const key = attri?.['buttonActionType'] as string;
      if (res?.formData?.payload?.isBeneficiaryFormValid) {
        ctaButtonSignalService.setSignal({
          [key]: res?.formData?.payload?.isBeneficiaryFormValid ? true : false,
        });
      }
    },
  });
}
