import { HttpErrorCode } from '@app/translations';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { AlertDetails, AlertStatusFlag } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ProjectAlertsState } from '../../state/project-alerts.state';

export function findAndShowAlertFromCode(
  store: Store,
  alertHandlerService: AlertHandlerService,
  alertCodes: string[],
) {
  const alerts = store.selectSnapshot(ProjectAlertsState.getSystemAlerts) ?? {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const alertToDisplay: any[] = [];
  Object.entries(alerts).forEach((alert) => {
    if (alertCodes.includes(alert[0])) {
      // to display only active alert
      if (alert[1]?.status === AlertStatusFlag.ACTIVE) {
        alertToDisplay.push(alert[1]);
      }
    }
  });
  alertToDisplay.forEach((alert, i) => {
    if (i <= 2) showAlertFromCode(alertHandlerService, alert);
  });
}

export function showAlertFromCode(alertHandlerService: AlertHandlerService, alertData: AlertDetails) {
  alertHandlerService.showAlertFn(alertData.type.toLowerCase(), alertData.description, alertData.title);
}

export function handleHttpErrorAlertCodes(
  alertHandlerService: AlertHandlerService,
  store: Store,
  statusCode: number,
  errorMessage: string,
) {
  const errorMap: { [key: number]: HttpErrorCode } = {
    400: HttpErrorCode.STATUS_400,
    401: HttpErrorCode.STATUS_401,
    402: HttpErrorCode.STATUS_402,
    403: HttpErrorCode.STATUS_403,
    404: HttpErrorCode.STATUS_404,
    405: HttpErrorCode.STATUS_405,
    500: HttpErrorCode.STATUS_500,
    501: HttpErrorCode.STATUS_501,
    502: HttpErrorCode.STATUS_502,
    503: HttpErrorCode.STATUS_503,
    504: HttpErrorCode.STATUS_504,
    505: HttpErrorCode.STATUS_505,
  };

  const errorCode =
    errorMap[statusCode] ??
    (statusCode >= 400 && statusCode < 500
      ? HttpErrorCode.STATUS_4XX_CATCH_ALL_ERROR
      : HttpErrorCode.STATUS_5XX_CATCH_ALL_ERROR);

  if (errorCode) {
    findAndShowAlertFromCode(store, alertHandlerService, [errorCode]);
  } else {
    alertHandlerService.showAlertFn('error', errorMessage);
  }
}
